import { Box, Button, FormControl, FormHelperText, Grid, InputAdornment, InputLabel, ListSubheader, OutlinedInput } from '@mui/material';
import * as React from 'react';
import PersonIcon from '@mui/icons-material/Person';
import OrderFooter from './OrderFooter'
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { useNavigate, useNavigationType } from 'react-router-dom';
import BadgeOutlinedIcon from '@mui/icons-material/BadgeOutlined';
import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined';

function validate(id: string, value: string) {
  const item = document.getElementById(id);
  if(value.trim() == '')
  {
    if (item) {
      item.style.display = 'block';
    }
    return false;
  }
  else
  {
    if (item) {
      item.style.display = 'none';
    }
    return true;
  }
}

export default function OrderPassengerBodyNext() {
  const navigate = useNavigate();
  const navigationType = useNavigationType();
  let curCus = sessionStorage.getItem('curCus') || 1;
  localStorage.setItem('curCus', "");
  let amount = sessionStorage.getItem('amount') || 0;
  if(sessionStorage.getItem('page') == 'OrderBill')
  {
    sessionStorage.setItem('curCus', "1");
    curCus = "1";
  }
  else
  {
    if (navigationType == 'PUSH')
    {
      curCus = Number(curCus) == Number(amount) ? Number(curCus) : Number(curCus) + 1;
      sessionStorage.setItem('curCus', curCus.toString());
    }
    else
    {
      let curCusBack = Number(curCus) - 1;
      sessionStorage.setItem('curCus', curCusBack.toString());
    }
  }
  sessionStorage.setItem('page','OrderPassenger');
  let PassengerCCCDPassport = localStorage.getItem('PassengerCCCDPassport'+curCus) == null ? '' : localStorage.getItem('PassengerCCCDPassport'+curCus);
  let PassengerName = localStorage.getItem('PassengerName'+curCus) == null ? '' : localStorage.getItem('PassengerName'+curCus);
  let PassengerPlace = localStorage.getItem('PassengerPlace'+curCus) == null ? '' : localStorage.getItem('PassengerPlace'+curCus);
  let PassengerYear = localStorage.getItem('PassengerYear'+curCus) == null ? '' : localStorage.getItem('PassengerYear'+curCus);

  let CheckPassengerCCCDPassport = PassengerCCCDPassport != '' ? true: false;
  let CheckPassengerName = PassengerName != '' ? true: false;
  let CheckPassengerYear = PassengerYear != '' ? true: false;
  let CheckPassengerPlace = PassengerPlace != '' ? true: false;

  let nextAction = '/OrderChair';
  let buttonText = 'Chọn ghế';
  if(amount > curCus)
  {
    nextAction = '/OrderPassenger';
    buttonText = 'Tiếp theo';
  }
  return (
    <Box sx={{ width: '100%' , backgroundColor: '#29ACE3', height: '100%'}}>
      <Grid xs={12} sx={{paddingTop: 1, borderTopRightRadius: 18, borderTopLeftRadius: 18, backgroundColor:'#ffffff'}}>
      <Grid container >
          <Grid item xs={1} sx={{height:'35px', paddingLeft: 1}}>
            <PersonIcon/>
          </Grid>
          <Grid item xs={8} sx={{textAlign:'left'}}>
            <ListSubheader component="div" sx={{ paddingLeft:'1px', color:'#0D11B2', lineHeight: '25px', fontWeight: 'bold', fontSize: '16px' }}>THÔNG TIN HÀNH KHÁCH</ListSubheader>
          </Grid>
        </Grid>
      </Grid>
      <Box
      component="form"
      sx={{
        '& .MuiTextField-root': { m: 1 },
         backgroundColor:'#ffffff'
      }}
      noValidate
      autoComplete="off"
    >
      <div>
        <InputLabel sx={{paddingLeft:'10px'}} htmlFor="outlined-adornment-amount">*Hành khách thứ {curCus}</InputLabel>
        <FormControl fullWidth sx={{ width: '95%',margin:'10px'}} required>
          <InputLabel htmlFor="outlined-adornment-amount">CCCD/Passport</InputLabel>
          <OutlinedInput
            id="outlined-adornment-amount"
            defaultValue={PassengerCCCDPassport}
            onChange={e => 
              {
                CheckPassengerCCCDPassport = validate('PassengerCCCDPassport',e.target.value.toString());
                localStorage.setItem('PassengerCCCDPassport'+curCus, e.target.value);
              }
            }
            startAdornment={<InputAdornment position="start"><BadgeOutlinedIcon/></InputAdornment>}
            label="CCCD/Passport"
          />
          <FormHelperText id="PassengerCCCDPassport" error sx={{display:'none'}}>Vui lòng nhập "CCCD/Passport"</FormHelperText>
        </FormControl>
        <FormControl fullWidth sx={{ width: '95%',margin:'10px' }} required>
          <InputLabel htmlFor="outlined-adornment-amount">Họ tên</InputLabel>
          <OutlinedInput
            id="outlined-adornment-amount"
            defaultValue={PassengerName}
            onChange={e => 
              {
                CheckPassengerName = validate('PassengerName',e.target.value.toString());
                localStorage.setItem('PassengerName'+curCus, e.target.value);
              }
            }
            startAdornment={<InputAdornment position="start"><PersonIcon/></InputAdornment>}
            label="Họ tên"
          />
          <FormHelperText id="PassengerName" error sx={{display:'none'}}>Vui lòng nhập "Họ tên người đặt vé"</FormHelperText>
        </FormControl>
        <FormControl fullWidth sx={{ width: '95%',margin:'10px' }} required>
          <InputLabel htmlFor="outlined-adornment-amount">Nơi sinh</InputLabel>
          <OutlinedInput
            id="outlined-adornment-amount"
            defaultValue={PassengerPlace}
            onChange={e => 
              {
                CheckPassengerPlace = validate('PassengerPlace',e.target.value.toString());
                localStorage.setItem('PassengerPlace'+curCus, e.target.value);
              }
            }
            startAdornment={<InputAdornment position="start"><LocationOnOutlinedIcon/></InputAdornment>}
            label="Nơi sinh"
          />
          <FormHelperText id="PassengerPlace" error sx={{display:'none'}}>Vui lòng nhập "Nơi sinh"</FormHelperText>
        </FormControl>
        <FormControl fullWidth sx={{ width: '95%',margin:'10px' }} required>
          <InputLabel htmlFor="outlined-adornment-amount">Ngày/Tháng/Năm sinh</InputLabel>
          <OutlinedInput
            id="outlined-adornment-amount"
            defaultValue={PassengerYear}
            onChange={e => 
              {
                CheckPassengerYear = validate('PassengerYear',e.target.value.toString());
                localStorage.setItem('PassengerYear'+curCus, e.target.value)
              
              }
            }
            startAdornment={<InputAdornment position="start"><CalendarMonthOutlinedIcon/></InputAdornment>}
            label="Ngày/Tháng/Năm sinh"
          />
          <FormHelperText id="PassengerYear" error sx={{display:'none'}}>Vui lòng nhập "Ngày/Tháng/Năm sinh"</FormHelperText>
        </FormControl>
      </div>
      </Box>
      <Box component="section" sx={{ textAlign: 'right', width: '100%', backgroundColor: '#ffffff', borderBottomRightRadius: 18, borderBottomLeftRadius: 18 }}>
        <Button startIcon={<NavigateNextIcon  />} onClick={()=>
          {
            if(!CheckPassengerCCCDPassport)
            {
              const item = document.getElementById('PassengerCCCDPassport');
              if (item) {
                item.style.display = 'block';
              }
            }
            if(!CheckPassengerName)
            {
              const item = document.getElementById('PassengerName');
              if (item) {
                item.style.display = 'block';
              }
            }
            if(!CheckPassengerPlace)
            {
              const item = document.getElementById('PassengerPlace');
              if (item) {
                item.style.display = 'block';
              }
            }
            if(!CheckPassengerYear)
            {
              const item = document.getElementById('PassengerYear');
              if (item) {
                item.style.display = 'block';
              }
            }
            if(CheckPassengerCCCDPassport && CheckPassengerName && CheckPassengerYear && CheckPassengerPlace)
            {
              navigate(nextAction);
            }
          }         
          } sx={{ marginRight: '10px'}}>
          {buttonText}
        </Button>
      </Box>
      <OrderFooter/>
    </Box>
  );
}