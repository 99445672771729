import { Autocomplete, Box, Button, FormControlLabel, Grid, Radio, RadioGroup, TextField } from '@mui/material';
import * as React from 'react';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import 'dayjs/locale/vi';
import SearchIcon from '@mui/icons-material/Search';
import { styled } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import OrderFooter from './OrderFooter'
import { MobileDatePicker } from '@mui/x-date-pickers';
import {Service} from './services/Service';
import { useEffect, useState } from 'react';
import dayjs, { Dayjs } from 'dayjs';
import { DemoItem } from '@mui/x-date-pickers/internals/demo';

const Label = styled('label')`
  padding: 0 0 4px;
  line-height: 1.5;
  display: block;
  font-weight: bold;
`;

export default function HomeNewsImageList() {
  const navigate = useNavigate();
  const [dataOptions, setDataOptions] = useState([]);
  const [dataRoute, setRouteData] = useState();
  const [show, setShow] = useState(false);
  let inputRef: { focus: () => void; };
  useEffect(() => {
    Service.GetRoutes().then(res => {
          //console.log(res)
          const updatedOptions = res.map((row: { name: string; id: string; }) => {
            return { label: row.name, id: row.id };
          });
          setDataOptions(updatedOptions)
      })
  }, [])
  let selectedRoute = JSON.parse(sessionStorage.getItem('route') || '{}');
  if(selectedRoute === null || Object.keys(selectedRoute).length === 0)
  {
    selectedRoute = null;
  }
  let selectedWay = sessionStorage.getItem('way') === null ? 'oneway' : sessionStorage.getItem('way');
  let selectedAmount = sessionStorage.getItem('amount') === null ? 1 : sessionStorage.getItem('amount');
  if(sessionStorage.getItem('amount') === null) sessionStorage.setItem('amount','1');
  
  var dateFrom = new Date();
  dateFrom.setDate(dateFrom.getDate() + 1);
  let defaultFromDate =  dayjs(dateFrom);
  var dateTo = new Date();
  dateTo.setDate(dateTo.getDate() + 2);
  let defaultToDate =  dayjs(dateTo);

  function setClicked(arg0: string): void {
    if(arg0 == 'twoway')
    {
      setShow(true)
    }
    else{
      setShow(false)
    }
    sessionStorage.setItem('way', arg0);
  }

  function setFromDate(arg0: Dayjs): void {
    sessionStorage.setItem('fromDate', (arg0 || "").toString());
  }

  function setToDate(arg0: Dayjs): void {
    sessionStorage.setItem('toDate', (arg0 || "").toString());
  }

  if(sessionStorage.getItem('fromDate') != null)
  {
    var fromDate = Date.parse((sessionStorage.getItem('fromDate') || "").toString());
    defaultFromDate =  dayjs(new Date(fromDate));
  }
  else{
    setFromDate(defaultFromDate);
  }

  if(sessionStorage.getItem('toDate') != null)
  {
    var toDate = Date.parse((sessionStorage.getItem('toDate') || "").toString());
    defaultToDate =  dayjs(new Date(toDate));
  }
  else{
    setToDate(defaultToDate);
  }
  sessionStorage.setItem('way', selectedWay || 'oneway');
  
  return (
    <Box sx={{ width: '100%' , backgroundColor: '#29ACE3', height: '100%'}}>
      <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="en">
        <Grid container justifyContent="center" alignItems="center" sx={{ borderRadius: '18px', backgroundColor:'#ffffff', height: '380px' }}>
          <Grid xs={12} sx={{paddingTop: '5px',paddingLeft: '20px'}}>
            <RadioGroup row defaultValue={selectedWay}>
              <FormControlLabel onClick={() => setClicked('oneway')} value="oneway" control={<Radio />} label="Một chiều" sx={{ backgroundColor: '#EBEDFE', WebkitBorderRadius: 16, width: '130px'}}/>
              <FormControlLabel onClick={() => setClicked('twoway')} value="twoway" control={<Radio />} label="Khứ hồi" sx={{ backgroundColor: '#EBEDFE', WebkitBorderRadius: 16, width: '120px'}}/>
            </RadioGroup>
          </Grid>
          <Grid xs={12} sx={{paddingTop: '10px',paddingLeft: '5px'}}>
            <Label>Chọn tuyến</Label>
            <Autocomplete
              disablePortal
              blurOnSelect="touch"
              options={dataOptions}
              size="small"
              isOptionEqualToValue={(option, value) => {
                if(!value) return false;
                if(option.value ==="") return false;
                if(option.value ==='""') return false;
                if(value.value ==="") return false;
                if(value.value ==='""') return false;
                return option.value === value.value;
              }}
              value={selectedRoute}
              onChange={(event, newValue) => {
                sessionStorage.setItem('route', JSON.stringify(newValue));
                setRouteData(newValue);
              }}
              renderInput={(params) => <TextField inputRef={input => {
                inputRef = input;
              }} {...params} name="Chọn tuyến"/>}
            />
          </Grid>
          <Grid xs={6} sx={{paddingTop: '10px',paddingLeft: '5px'}}>
            <DemoItem label="Ngày đi">
              <MobileDatePicker slotProps={{ textField: { size: 'small' } }} format="DD/MM/YYYY" defaultValue={defaultFromDate} onChange={(newValue) => { setFromDate(newValue || dayjs(new Date())); }}/>
            </DemoItem>
          </Grid>
          <Grid xs={6} sx={{paddingTop: '10px',paddingLeft: '5px'}}>
          {(show || selectedWay == 'twoway') && <DemoItem label="Ngày về">
              <MobileDatePicker slotProps={{ textField: { size: 'small' } }} format="DD/MM/YYYY" defaultValue={defaultToDate} onChange={(newValue) => { setToDate(newValue || dayjs(new Date())); }}/>
            </DemoItem>}
          </Grid>
          <Grid xs={12} sx={{paddingTop: '10px',paddingLeft: 1,textAlign: 'center', marginBottom: '1px'}}>
            <TextField
              sx={{width: '120px'}}
              id="outlined-number"
              label="Số lượng khách"
              type="number"
              size="small"
              defaultValue={selectedAmount}
              onChange={e => sessionStorage.setItem('amount', e.target.value)}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>
          <Grid xs={12} sx={{paddingLeft: 1,textAlign: 'center', marginBottom: '1px'}}>
            <Box component="section" sx={{ height: '40px', paddingTop:'20px', paddingBottom:'10px',textAlign: 'center' }}>
              <Button variant="contained" startIcon={<SearchIcon />} onClick={()=>{
                if(dataRoute || selectedRoute != '')
                {
                navigate("/OrderTripFrom")
                }
                else{
                  alert("Vui lòng chọn tuyến");
                  inputRef.focus();
                }
                
                }}>
                    Tìm kiếm
              </Button>
            </Box>
          </Grid>
        </Grid>
        
        <OrderFooter/>
      </LocalizationProvider>
    </Box>
  );
}